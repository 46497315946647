import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import ogImage from '../../resources/images/logos/featured_image.jpg'

const sla = () => {
  return (
    <Fragment>
      <Layout>
        <Helmet>
          <title>DataOps Support SLAs | Lenses.io</title>
          <meta
            name="description"
            content="DataOps Support SLAs from Lenses.io"
          />
          <meta
            name="keywords"
            content="Support terms, SLA, Lenses.io, service level agreement"
          />
          <meta property="og:image" content={ogImage} />
        </Helmet>

        <div className="px-2 text-dark f-16 my-5 py-5 container-1 mobile-margin-reset">
          <div className="pt-5">
            <h1 className="h2-like">Service Level Agreement (SLA)</h1>
            <p>
              A description of available technical Support Levels for Customers
              and Support terms and conditions.
            </p>
            <h2 className="h3-like pb-0">1. Support services</h2>
            <p>Support Services are available in English:</p>
            <ol style={{ fontSize: '18px' }}>
              <li>
                Via the online support portal at{' '}
                <a
                  href="https://support.lenses.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.lenses.io
                </a>
              </li>
              <li>
                Using the email address{' '}
                <span style={{ fontFamily: 'CeraPro, arial' }}>
                  <b>
                    support
                    <span style={{ width: '1px', display: 'inline-block' }}>
                      {' '}
                    </span>
                    @lenses.io
                  </b>
                </span>{' '}
              </li>
            </ol>
            <p>
              When a Support ticket has been created, we reserve the right to
              review and reassign the priority level based on the definitions
              specified in section 3 of this policy.
            </p>
            <p>
              We will respond within the applicable Initial Response Time based
              on the SLAs specified in section 4 below and will subsequently
              communicate with the Customer using the Support portal, email or
              conference call - whichever channel is determined most
              appropriate.
            </p>
            <h2 className="h3-like pb-0">2. Support levels</h2>
            <p>The following Support levels are available:</p>
            <table className="table">
              <thead>
                <tr className="border-pricing-team">
                  <th
                    className="border-right border-pricing-team pt-3 pl-4 footer-color"
                    scope="col"
                  >
                    Support level
                  </th>
                  <th
                    className="border-right border-pricing-team pt-3 pl-4 footer-color"
                    scope="col"
                  >
                    Professional
                  </th>
                  <th
                    className="border-right border-pricing-team pt-3 pl-4 footer-color"
                    scope="col"
                  >
                    Premium
                  </th>
                </tr>
              </thead>
              <tbody className="border-pricing-table">
                <tr>
                  <td scope="col" className="pl-4 border-right">
                    Support Coverage
                  </td>
                  <td className="pl-4 border-right">8x5 (CET or EST)</td>
                  <td className="pl-4">24 x 7</td>
                </tr>
                <tr>
                  <td scope="col" className="pl-4 border-right">
                    Support Portal Contacts
                  </td>
                  <td className="pl-4 border-right">Up to 2 contacts</td>
                  <td className="pl-4">Up to 5 contacts</td>
                </tr>
              </tbody>
            </table>

            <h2 className="h3-like pb-0">3. Issue priority definition</h2>

            <table className="table">
              <thead>
                <tr className="border-pricing-team">
                  <th
                    className="border-right border-pricing-team pt-3 pl-4 footer-color"
                    scope="col"
                    style={{ width: '135px' }}
                  >
                    Priority
                  </th>
                  <th
                    className="border-right border-pricing-team pt-3 pl-4 footer-color"
                    scope="col"
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody className="border-pricing-table">
                <tr>
                  <td scope="col" className="pl-4 border-right">
                    <b>
                      P1 <span className="light-gray">-</span> Urgent
                    </b>
                  </td>
                  <td className="pl-4 border-right">
                    Any Software problem resulting in complete outage or loss of
                    service or functionality in production systems which is not
                    fixed after restarting the service.
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="pl-4 border-right">
                    <b>
                      P2 <span className="light-gray">-</span> High
                    </b>
                  </td>
                  <td className="pl-4 border-right">
                    Any Software problem that significantly impacts the service
                    or functionality in production systems, or causes
                    instability with regular interruptions of service, but does
                    not cause a complete outage.{' '}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="pl-4 border-right">
                    <b>
                      P3 <span className="light-gray">-</span> Normal
                    </b>
                  </td>
                  <td className="pl-4 border-right">
                    Any Software problem impacting non-critical functionality in
                    production or non-production systems, or a time-sensitive
                    issue impacting performance or deliverables requiring
                    resolution.{' '}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="pl-4 border-right">
                    <b>
                      P4 <span className="light-gray">-</span> Low
                    </b>
                  </td>
                  <td className="pl-4 border-right">
                    Any cosmetic or non-functional issues, questions around
                    processes or documentation, or product enhancement and new
                    feature requests (which can be triaged here but will be
                    tracked to completion elsewhere).{' '}
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 className="h3-like pb-0">4. Initial response time</h2>
            <p>
              The Initial Response Time shall be measured as the time between a
              ticket being submitted and the time{' '}
              <span className="code-text">Lenses.io </span>
              acknowledges receipt of the ticket. The Customer understands that
              full resolution of an issue is not guaranteed and that the actual
              time required to resolve any issue may be longer than the initial
              response time.
            </p>

            <table className="table table-bordered">
              <thead>
                <tr className="border-pricing-team">
                  <th
                    style={{ width: '22%' }}
                    className="pl-4"
                    scope="col"
                  ></th>
                  <th style={{ width: '39%' }} className="pl-4" scope="col">
                    Professional
                  </th>
                  <th style={{ width: '39%' }} className="pl-4" scope="col">
                    Premium
                  </th>
                </tr>
              </thead>
              <tbody className="border-pricing-table">
                <tr>
                  <th className="pl-4 border-right">P1</th>
                  <td className="pl-4 border-right">6 Hours</td>
                  <td className="pl-4 border-right">2 Hours</td>
                </tr>
                <tr>
                  <th className="pl-4 border-right">P2</th>
                  <td className="pl-4 border-right">2 Business Days</td>
                  <td className="pl-4 border-right">8 Hours</td>
                </tr>
                <tr>
                  <th className="pl-4 border-right">P3</th>
                  <td className="pl-4 border-right">3 Business Days</td>
                  <td className="pl-4 border-right">2 Business Days</td>
                </tr>
                <tr>
                  <th className="pl-4 border-right">P4</th>
                  <td className="pl-4 border-right">5 Business Days</td>
                  <td className="pl-4 border-right">3 Business Days</td>
                </tr>
              </tbody>
            </table>

            <h2 className="h3-like pb-0">5. Customer responsibilities</h2>
            <p>
              The Customer shall make reasonable efforts to resolve any problems
              before raising a Support ticket. The Customer shall supply to{' '}
              <span className="code-text">Lenses.io </span>a detailed
              description of any fault requiring Support Services and the
              circumstances in which it arose. The Customer shall also submit
              sufficient material and information to enable the
              <span className="code-text"> Lenses.io </span>
              support staff to replicate the problem, including:
            </p>
            <span className="pt-0 code-text">
              &nbsp;&nbsp;logs
              <br />
              &nbsp;&nbsp;screenshots
              <br />
              &nbsp;&nbsp;versions of the software
              <br />
              &nbsp;&nbsp;and any other evidence or information requested by the
              Support team
            </span>

            <h2 className="h3-like pb-0">6. Maintenance services</h2>
            <p>
              <span className="code-text">Lenses.io </span>
              will provide regular updates on the reported issue and on any
              solution or workaround designed to solve or bypass the reported
              issue. If the issue has been corrected in a maintenance release
              for the Customer’s installed version of the software,
              <span className="code-text"> Lenses.io </span> will inform the
              Customer promptly of its availability and the Customer must
              install and implement the applicable maintenance release as soon
              as possible.
            </p>
            <p>
              <span className="code-text">Lenses.io </span>
              Software is versioned using the following scheme:{' '}
              <b>
                {' '}
                Χ.Υ.<span className="light-gray">Ζ</span>
              </b>
              , where <b> Χ </b> is the major version number, <b>Υ</b> is the
              minor version number, and <b>Z</b> is the patch version number.
            </p>
            <ul
              style={{ listStyleType: 'disc', fontSize: '18px' }}
              className="pl-4 ml-1 pt-0 pb-3"
            >
              <li>
                The major number increments every time there are significant new
                features, enhancements or modifications.
              </li>
              <li>
                The minor number increments every time there are feature
                updates, smaller enhancements or maintenance releases.
              </li>
              <li>
                The patch number increments every time there is a maintenance
                release or bug-fix.
              </li>
            </ul>
            <p>
              The two most recent major versions are being actively supported by{' '}
              <span className="code-text">Lenses.io</span>. Patches are always
              applied to the latest release of the supported major versions.
            </p>
            <p>
              New features target the latest major version, fixes target the two
              most recent major versions. Security updates and important fixes
              to older versions may be applied on a case by case basis.
            </p>
            <p>
              <span className="code-text">Lenses.io </span> always recommends
              that the latest major version of the software is being used. If
              for some reason a previous major version is required, the latest
              available release must be employed.
            </p>

            <h2 className="h3-like pb-0">7. Exclusions</h2>
            <p>
              <span className="code-text">Lenses.io </span> will have no
              obligation to provide Support or address issues in the following
              situations:
            </p>
            <ol style={{ fontSize: '18px' }}>
              <li>
                The Software has been changed or modified in an unauthorised
                manner.
              </li>
              <li>
                The Software has been used incorrectly by the Customer
                (including use of the Software on faulty equipment or hardware).
              </li>
              <li>
                The issue relates to any programs not supplied by or approved in
                writing by <span className="code-text"> Lenses.io</span>.
              </li>
              <li>
                The issue is caused by an act beyond the reasonable control of{' '}
                <span className="code-text"> Lenses.io </span> - such as a
                hardware malfunction, the incorrect configuration of a data
                center, network latency, or Customer negligence.
              </li>
              <li>
                The Customer is using any version of the Software for which
                maintenance releases have been discontinued by
                <span className="code-text"> Lenses.io</span>.
              </li>
              <li>
                The Customer has not installed and implemented all the latest
                available maintenance release(s) for the installed version of
                the <span className="code-text"> Lenses.io </span>
                Software version they are using. or
              </li>
              <li>
                The Customer has not paid the Support Services fees when due.
              </li>
              <li>Lenses support SLAs are not extended to connectors.</li>
            </ol>
            <hr className="pt-2" />
            <p className="pt-2">
              Last update: 15 Jul 2021
              <br />
              <br />
              <small>
                {' '}
                These terms and conditions may be updated from time to time,
                with the understanding that any such updates will not materially
                reduce the level of Support experienced by the Customer.
              </small>
            </p>
          </div>
        </div>
      </Layout>
    </Fragment>
  )
}

export default sla
